/* Define CSS classes for different font sizes */
.font-size-8 {
    font-size: 8px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-32 {
    font-size: 32px;
}

.font-size-40 {
    font-size: 40px;
}

.font-size-48 {
    font-size: 48px;
}

.font-size-56 {
    font-size: 56px;
}

.font-size-64 {
    font-size: 64px;
}

.font-size-72 {
    font-size: 72px;
}

.font-size-80 {
    font-size: 80px;
}

.font-size-96 {
    font-size: 96px;
}

.font-size-112 {
    font-size: 112px;
}

.font-size-128 {
    font-size: 128px;
}