.page-main-holder {
    background: black;
    color: whitesmoke;
    width: 100dvw;
    height: 100dvh;
}

/* 
.flagholder {
    margin-inline-start: 100px;
    margin-inline-end: 100px;
}
*/

.flags img {
    height: 128px;
    width: 128px;
}

.flags h3 {
    cursor: pointer;
}

.round-img-disabled {
    opacity: 0.1;
    cursor: not-allowed;
}

.round-img-border {
    opacity: 0.5;
}

.round-img-border:hover {
    border: 2px solid whitesmoke;
    border-radius: 50%;
    opacity: 1;
}

.round-img-holder {
    color: #000000;
}

.round-img-holder:hover {
    color: whitesmoke;
    cursor: pointer;
    transform: scale(1.05);
    transition: transform 0.3s;
}

.stadium-box {
    width: 300px;
    height: 300px;
    opacity: 0.7;
}

.stadium-box:hover {
    cursor: pointer;
    opacity: 1;
    background-color: yellowgreen;
    color: black;
    transform: scale(1.05);
    transition: transform 0.3s;
}

.bowler-box {
    opacity: 0.7;
}

.bowler-box:hover {
    cursor: pointer;
    opacity: 1;
    background-color: yellowgreen;
    color: black;
    transform: scale(1.05);
    transition: transform 0.3s;
}