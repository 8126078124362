.full-screen {
    width: 100vw;
    height: 100vh;
}

.full-height {
    height: 100vh;
}

.max-height-90vh {
    max-height: 90vh
}

.text-size-label {
    font-size: x-large;
}

.btn-kolapata {
    color: #000;
    background-color: #96F164 !important;
    border-color: #96F164 !important;
}

.bg-beige {
    background-color: beige;
}

.bg-cornsilk {
    background-color: cornsilk;
}

.bg-aliceblue {
    background-color: aliceblue !important;
}

.bg-cornflowerblue {
    background-color: cornflowerblue !important;
}

.bg-back1 {
    background: url(./../Asset/background/xyz.webp) center center/cover no-repeat fixed;
    /* Replace 'your-image-url.jpg' with your actual image URL */
    height: 100vh;
    /* Ensure full viewport height */
}

.bg-glass {
    background: rgba(255, 255, 255, 0.1);
    /* Adjust the opacity as needed */
    backdrop-filter: blur(10px);
    /* Adjust the blur amount as needed */
}

.bg-beige-gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fefcea+0,f5f5dc+100 */
    background: #fefcea;
    /* Old browsers */
    background: -moz-linear-gradient(top, #fefcea 0%, #f5f5dc 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #fefcea 0%, #f5f5dc 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #fefcea 0%, #f5f5dc 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fefcea', endColorstr='#f5f5dc', GradientType=0);
    /* IE6-9 */

}

.bg-beige-gradient-dark {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fefcea+0,f5f5dc+57 */
    background: #fefcea;
    /* Old browsers */
    background: -moz-linear-gradient(top, #fefcea 0%, #f5f5dc 57%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #fefcea 0%, #f5f5dc 57%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #fefcea 0%, #f5f5dc 57%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fefcea', endColorstr='#f5f5dc', GradientType=0);
    /* IE6-9 */

}

.bg-black-gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4c4c4c+0,595959+12,666666+25,474747+39,2c2c2c+50,000000+51,111111+60,2b2b2b+76,1c1c1c+91,131313+100;Black+Gloss+%231 */
    background: #4c4c4c;
    /* Old browsers */
    background: -moz-linear-gradient(top, #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #111111 60%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #111111 60%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #111111 60%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4c4c4c', endColorstr='#131313', GradientType=0);
    /* IE6-9 */

}

.bg-sky-gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d6f9ff+0,9ee8fa+100;Blue+3D */
    background: #d6f9ff;
    /* Old browsers */
    background: -moz-linear-gradient(top, #d6f9ff 0%, #9ee8fa 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #d6f9ff 0%, #9ee8fa 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #d6f9ff 0%, #9ee8fa 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d6f9ff', endColorstr='#9ee8fa', GradientType=0);
    /* IE6-9 */
}

.bg-greenish-gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#cdeb8e+0,a5c956+100;Green+3D+%232 */
    background: rgb(205, 235, 142);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(205, 235, 142, 1) 0%, rgba(165, 201, 86, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(205, 235, 142, 1) 0%, rgba(165, 201, 86, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(205, 235, 142, 1) 0%, rgba(165, 201, 86, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cdeb8e', endColorstr='#a5c956', GradientType=0);
    /* IE6-9 */
}

.bottom-border-gray {
    border-bottom: 1px solid gray;
}

.h-0px {
    height: 0px;
    width: 0px;
}

.w-50px {
    width: 50px;
}

.w-100px {
    width: 100px !important;
}

.w-130px {
    width: 130px !important;
}

.w-150px {
    width: 150px !important;
}

.w-200px {
    width: 200px !important;
}

.w-220px {
    width: 220px !important;
}

.w-250px {
    width: 250px !important;
}

.w-300px {
    width: 300px;
}

.w-350px {
    width: 350px;
}

.w-500px {
    width: 500px;
}

.w-32p {
    width: 32%;
}

.trans {
    transition: all 1.5s ease;
}

.op-0 {
    opacity: 0;
}

.br-left {
    border-radius: 50% 0 0 50% !important;
}

.br-right {
    border-radius: 0 50% 50% 0 !important;
}

.rounded-custom-right {
    border-radius: 18px 18px 0px 18px !important;
}

.rounded-custom-left {
    border-radius: 18px 18px 18px 0px !important;
}

.mh-700 {
    max-height: 600px;
    overflow-y: auto;
}

.mini-text {
    font-size: 12px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: 700;
}

.wh-36px {
    width: 36px;
    height: 36px;
}

.img-btn {
    appearance: "button";
}

.img-btn:hover {
    appearance: "button";
    color: #d6f9ff;
}

.svg-download {
    fill: black;
    cursor: pointer;
}

.svg-download path {
    stroke: yellowgreen;
    stroke-width: 1px;
}

.svg-download:hover {
    fill: yellowgreen;
}

table {
    table-layout: fixed;
    width: 100%;
}

p::first-letter {
    text-transform: capitalize;
}

p {
    margin-bottom: 0px !important;
}

/* fonts.css */

@font-face {
    font-family: 'Volcano';
    src: url('../Asset/font/FairyTale-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'VolcanoBold';
    src: url('../Asset/font/Volcano-Book.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

.font-volcano {
    font-family: "Volcano", Georgia, 'Times New Roman', Times, serif;
}

.wh-382px {
    width: 382px;
    height: 382px;
}